import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "image", "button" ]

  setActiveThumbnail(event) {
    let buttons = this.buttonTargets;

    buttons.forEach((button) => {
      button.style.outline = "none";
    });

    event.currentTarget.style.outline = "1px solid #AA001D";
  }

  switchImage(event) {
    this.setActiveThumbnail(event)
    this.imageTarget.src = event.currentTarget.dataset.url
  }
}
